import React, { useEffect } from "react"
import cx from "classnames"
import ScrollReveal from "scrollreveal"
import loadable from "@loadable/component"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { IMG } from "../const"
import "../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../assets/styles/commons/_common__headline__stairsDown.scss"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/dedicated-team.module.scss"
import HeaderImages from "../components/dedicated-team/HeaderImages"
import HireBlock from "../components/dedicated-team/HireBlock"
import LeadingBlock from "../components/dedicated-team/LeadingBlock"
import ContactForm from "../components/contact-form"
import {
  HIRE_BLOCK_TEXT,
  TECHNOLOGIES_FRONTEND,
  TECHNOLOGIES_BACKEND,
} from "../components/dedicated-team/const"

const CaseStudies = loadable(() => import("../components/case-studies"))
const TechnologyContainer = loadable(() =>
  import("../components/dedicated-team/TechnologyContainer")
)

const DedicatedTeam = () => {
  useEffect(() => {
    ScrollReveal().reveal(`#FrontendTechnologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
    ScrollReveal().reveal(`#BackendTechnologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
    ScrollReveal().reveal(`#TrustedBy li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 100,
      delay: 100,
    })
  }, [])

  return (
    <Layout
      pageTitle="Nearshore Software Development"
      metaTitle="Hire Expert Nearshore Developers - LaSoft's Dedicated Team Solutions"
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-nearshor-software.jpg`}
    >
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <>
            <main className={cx(styles.dedicatedTeamBlock, "glossy")}>
              <article>
                <div
                  className={cx(
                    styles.dedicatedTeamBlock__headline,
                    "upTo2cols"
                  )}
                >
                  <div className="common__headline__stairsDown">
                    <h1 className={styles.smallH1}>
                      Nearshore software development
                    </h1>
                    <p>
                      <Link
                        to="#contact-form"
                        className="goldFatLink nearshore-talk"
                      >
                        Let’s talk
                      </Link>
                    </p>
                    <p>
                      <mark>
                        LaSoft's dedicated teams are an ideal solution for
                        nearshoring needs, combining local expertise with global
                        talent.
                      </mark>
                    </p>
                    <p>
                      We apply a structured and tested approach to building
                      professional, long-term dedicated teams, specifically
                      tailored for nearshore software development and
                      nearshoring partnerships. This method ensures effective
                      team integration and alignment with our partner's
                      objectives.
                    </p>
                  </div>
                  <HeaderImages />
                </div>
                <section
                  className={cx(
                    styles.dedicatedTeamBlock__accompany,
                    "commonGlossy__article__section upTo2cols"
                  )}
                >
                  <h2 className="at640__pr50p">
                    We will be there for you along&nbsp;the&nbsp;way
                  </h2>
                  <div>
                    <p
                      className={cx(
                        styles.dedicatedTeamBlock__accompany__leadQuote,
                        "common__leadQuote"
                      )}
                    >
                      <strong>LaSoft’s dedicated team</strong> excels in
                      nearshore software development, comprising seasoned
                      professionals in the field. We specialize in hiring,
                      training, and retaining top talent for nearshoring needs,
                      offering our engineering services globally with a focus on
                      nearshoring efficiency and quality.
                    </p>
                  </div>
                  <div>
                    <p className={styles.dedicatedTeamBlock__accompany__motto}>
                      <mark>
                        With almost a decade of providing IT services globally,
                        including in Switzerland, Germany, the UK, the US, and
                        Saudi Arabia, LaSoft has honed its expertise in
                        nearshore software development. Our experience
                        underscores the importance of working with engineers who
                        not only uphold the highest standards of quality,
                        security, and stability in system building but also
                        excel in the realms of nearshoring, ensuring seamless
                        collaboration and alignment with our customers' needs.
                      </mark>
                    </p>
                    <p
                      className={
                        styles.dedicatedTeamBlock__accompany__strongEmphasis
                      }
                    >
                      <em>
                        <strong>
                          Our dedicated team model is ideally suited for those
                          seeking staff augmentation, led by professionals adept
                          in nearshore development services. It's perfect for
                          planned or ongoing projects, leveraging nearshoring
                          for enhanced efficiency and collaboration.
                        </strong>
                      </em>
                    </p>
                    <p>
                      <Link
                        to="#contact-form"
                        className="goldFatLink nearshore-talk-two"
                      >
                        Let’s talk
                      </Link>
                    </p>
                  </div>
                </section>
                <section
                  className={cx(
                    styles.dedicatedTeamBlock__techStack,
                    "commonGlossy__article__section"
                  )}
                >
                  <h2>Technologies We Use</h2>
                  <p>
                    LaSoft employs a structured and tested approach to build
                    professional, long-term dedicated teams, ideal for nearshore
                    software development. This methodology ensures our partners
                    receive teams that not only meet the highest professional
                    standards but also align seamlessly with nearshoring
                    objectives.
                  </p>
                  <div className="upTo2cols">
                    <div>
                      <h5>Frontend</h5>
                      <ul
                        className="common__list__techLogos-rounded"
                        id="FrontendTechnologies"
                      >
                        {TECHNOLOGIES_FRONTEND.map(technology => (
                          <TechnologyContainer
                            technology={technology}
                            key={technology.id}
                          >
                            <figure>
                              <img
                                src={`${IMG.ICONS}/logos/${technology.icon}`}
                                width="32"
                                height="32"
                                loading="lazy"
                                alt={technology.id}
                              />
                              <figcaption>{technology.name}</figcaption>
                            </figure>
                          </TechnologyContainer>
                        ))}
                      </ul>
                      <h5>Backend</h5>
                      <p>
                        Can be chosen in accordance with your business needs
                        or&nbsp;preferences
                      </p>
                      <ul
                        className="common__list__techLogos-rounded"
                        id="BackendTechnologies"
                      >
                        {TECHNOLOGIES_BACKEND.map(technology => (
                          <TechnologyContainer
                            technology={technology}
                            key={technology.id}
                          >
                            <figure>
                              <img
                                src={`${IMG.ICONS}/logos/${technology.icon}`}
                                width="32"
                                height="32"
                                loading="lazy"
                                alt={technology.id}
                              />
                              <figcaption>{technology.name}</figcaption>
                            </figure>
                          </TechnologyContainer>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.dedicatedTeamBlock__techStack__pic}>
                      {/*
                          Images in AVIF format do not give an acceptable ratio of quality and weight,
                          so in this case, we do not use them -- karlsson
                      */}
                      <picture
                        className={styles.dedicatedTeamBlock__techStack__pic1}
                      >
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/1.webp,	${IMG.DEDICATED_TEAM}/code/1@2x.webp 2x,	${IMG.DEDICATED_TEAM}/code/1@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/1.jpg,	${IMG.DEDICATED_TEAM}/code/1@2x.jpg 2x,		${IMG.DEDICATED_TEAM}/code/1@3x.jpg 3x`}
                        />
                        <img
                          src={`${IMG.DEDICATED_TEAM}/code/1@3x.jpg`}
                          alt="React page code"
                          width="310"
                          height="372"
                          loading="lazy"
                        />
                      </picture>
                      <picture
                        className={styles.dedicatedTeamBlock__techStack__pic2}
                      >
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/2.webp,	${IMG.DEDICATED_TEAM}/code/2@2x.webp 2x,	${IMG.DEDICATED_TEAM}/code/2@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DEDICATED_TEAM}/code/2.jpg,	${IMG.DEDICATED_TEAM}/code/2@2x.jpg 2x,		${IMG.DEDICATED_TEAM}/code/2@3x.jpg 3x`}
                        />
                        <img
                          src={`${IMG.DEDICATED_TEAM}/code/2@3x.jpg`}
                          alt="React helmet code"
                          width="315"
                          height="330"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </section>
                <HireBlock
                  title="How Do I Hire Nearshore Developers as a Dedicated Team at LaSoft?"
                  text=" We use a structured and tested approach to build a professional, long-term dedicated team for our partners."
                  options={HIRE_BLOCK_TEXT}
                />
                <section
                  className={cx(
                    styles.dedicatedTeamBlock__trustedBy,
                    "commonGlossy__article__section"
                  )}
                  id="TrustedBy"
                >
                  <h2>We are trusted by</h2>
                  <ul>
                    <li>
                      <img
                        src={`${IMG.ABOUT}/logo-CalvinKlein.svg`}
                        alt="Calvin Klein"
                        width="138"
                        loading="lazy"
                      />
                    </li>
                    <li>
                      <img
                        src={`${IMG.ABOUT}/logo-sOliver.svg`}
                        alt="s.Oliver"
                        width="138"
                        loading="lazy"
                      />
                    </li>
                    <li>
                      <img
                        src={`${IMG.ABOUT}/logo-HugoBoss.svg`}
                        alt="Hugo Boss"
                        width="138"
                        loading="lazy"
                      />
                    </li>
                  </ul>
                </section>
                <section
                  className={cx(
                    styles.dedicatedTeamBlock__talent,
                    "commonGlossy__article__section"
                  )}
                >
                  <h2>LaSoft Nearshoring Talent</h2>
                  <p className="at768__w50p">
                    We use a structured and tested approach to build a
                    professional, long-term dedicated team of nearshore
                    developers for our partners.
                  </p>
                  <ul className={styles.listIconic}>
                    <li className={styles.icon__tool}>
                      <h4>
                        Get a great experience in building world-class projects
                      </h4>
                      <p>
                        We create teams of professional developers building
                        complex software for IT solutions worldwide. More than
                        that, we constantly improve employees’ professional
                        skills with the help of mentorship, a peer-to-peer
                        approach, and our own IT&nbsp;academy.
                      </p>
                    </li>
                    <li className={styles.icon__minimize}>
                      <h4>Well-screened</h4>
                      <p>
                        Our tried and true approach allows us to hire the best
                        candidates on the market. To get hired, engineers have
                        to pass a 3‑level screening process where our experts
                        check their interpersonal, communicational, English, and
                        technical skills.
                      </p>
                    </li>
                    <li className={styles.icon__mic}>
                      <h4>We speak good English</h4>
                      <p>
                        We don’t just take pride in the high level of technology
                        languages we speak. All of our employees have good
                        English skills and communicate with clients directly on
                        a daily basis. We support this by offering company‑based
                        English language courses free of charge for our
                        employees.
                      </p>
                    </li>
                    <li className={styles.icon__globe}>
                      <h4>
                        Get to know standard software development approaches and
                        tools
                      </h4>
                      <p>
                        Working with international clients for nearly
                        10&nbsp;years, we have gained excellent experience using
                        modern software tools and processes such as scrum and
                        agile development. The adaptation process of our team
                        members usually does not take long.
                      </p>
                    </li>
                    <li className={styles.icon__infinity}>
                      <h4>Stay with a company long‑term</h4>
                      <p>
                        On average employees stay in the company for
                        3&nbsp;years. We are proud to say that our staff
                        turnover is very low.
                      </p>
                    </li>
                  </ul>
                </section>

                <div className="commonGlossy__article__case-studies">
                  <CaseStudies title="" screenWidth={width} />
                </div>

                <LeadingBlock linkClassName="nearshore-client" />
              </article>
            </main>
            <section className="footerForm">
              <div id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default DedicatedTeam
